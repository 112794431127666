/* eslint-disable  */
import * as apiObject from "@/utils/api";
import { Typography } from "@mui/material";
import TextInputControl from "core/components/forms/TextInputControl";
import * as Images from "core/utils/Images";
import Image from "next/image";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import functions from "core/utils/functions";
import JwtUtil from "core/utils/JwtUtil";

//로그인 전역상태
import { loginUserState } from "core/stores/userStore";
import { useRecoilState } from "recoil";

//서버에서 토큰발행을 안하므로 by nohsn 2023.05.26
import * as Cookies from "core/utils/cookies";

//style
import Config from "@/env/Config";
import Toast from "core/components/common/Toast";
import {
  BotDiv,
  Divide,
  InputStyles,
  LoginBox,
  LoginBtn,
  MG_BOT_20,
  MG_BOT_40,
  NewMainContainer
} from "core/styles/login-style";
import { LoadingWrap } from "core/styles/order/style";
import _ from "lodash";

export default function Login() {
  const router = useRouter();

  const { rurl } = router.query;
  // 로그인 정보
  const [loginUserInfo, setLoginUserInfo] = useRecoilState(loginUserState);
  const [login, setLogin] = useState({
    staff_id: "",
    staff_pw: ""
  });

  // 로딩 여부
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const auth = Cookies.getCookie("Authentication");
    if (!functions.isEmpty(loginUserInfo?.staff_id) && auth) {
      setTimeout(() => {
        if (!rurl) return router.replace("/");
        window.location.href = String(rurl) + "?token=" + auth;
      }, 500);
      return;
    }
    setLoading(false);
  }, []);

  const getExpireTime = () => {
    const envData = Config.tokenExpireTime
      ? String(Config.tokenExpireTime)
      : "32400000";
    const envExpireTime = parseInt(envData) || 1000 * 60 * 60;
    let expires = new Date();
    const time = expires.getTime();
    let expireTime = time + envExpireTime; //1hour
    expires.setTime(expireTime);
    return expireTime;
  };
  const saveCookie = (
    token: string,
    staffId: string,
    companyCode: string,
    userType: string
  ) => {
    Cookies.setCookie(
      "os_type",
      JSON.stringify({
        os: "web",
        device_info: window.navigator.userAgent
      }),
      {
        path: "/",
        expireTime: getExpireTime(),
        httpOnly: false
      }
    );

    //auth 서버에서는 company 코드 사용 X
    // Cookies.setCookie("company", companyCode, {
    //   path: "/",
    //   expireTime: getExpireTime(),
    //   httpOnly: false
    // });
    Cookies.setCookie("Authentication", token, {
      path: "/",
      expireTime: getExpireTime(),
      // expires: getExpireTime(),
      maxAge: 60 * 30, //30분
      httpOnly: false
    });
    Cookies.setCookie(
      "LoginUser",
      JSON.stringify({
        staff_id: staffId
      }),
      {
        path: "/",
        expireTime: getExpireTime(),
        httpOnly: false
      }
    );
    Cookies.setCookie(
      "NewLoginUser",
      JSON.stringify({
        staff_id: staffId
      }),
      {
        path: "/",
        expireTime: getExpireTime(),
        httpOnly: false
      }
    );
    Cookies.setCookie("userType", userType, {
      path: "/",
      expireTime: getExpireTime(),
      httpOnly: false
    });
  };

  const handleLogin = async () => {
    if (login.staff_id.trim() == "")
      return window.alert("아이디를 입력해주세요.");
    else if (login.staff_pw.trim() == "")
      return window.alert("비밀번호를 입력해주세요.");

    try {
      setLoading(true);
      const res = await apiObject.post("/api/member/member.php?action=login", {
        staff_id: login.staff_id,
        staff_pw: login.staff_pw
      });
      if (res.state !== "true") {
        setLoading(false);
        return Toast(res?.message ? res?.message : "오류가 발생하였습니다.");
      } else if (_.isEmpty(res.token)) {
        setLoading(false);
        return Toast(res?.message ? res?.message : "login failed");
      } else {
        const userInfo = {
          state: res?.state,
          message: res?.message,
          staff_id: res?.staff_id,
          photo_file: res?.photo_file,
          staff_name: res?.staff_name,
          org_no: res?.org_no,
          class_position: res?.class_position,
          duty_position: res?.duty_position,
          admin_org_no: res?.admin_org_no,
          erp: res?.erp,
          is_master: res?.is_master === "1" ? true : false, //  res?.is_master,
          office_phone: res?.office_phone,
          company: res?.company,
          userType: res?.userType ? res.userType : "staff",
          permissions: res?.permissions
        };

        let token = JwtUtil.sign(userInfo);
        saveCookie(
          token,
          userInfo.staff_id,
          userInfo.company,
          userInfo.userType
        );
        setLoginUserInfo(userInfo);
        setTimeout(() => {
          if (!rurl) return router.replace("/");
          window.location.href = String(rurl) + "?token=" + token;
        }, 500);
      }
    } catch (err) {}
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const handleEnterPress = (e: any) => {
    if (e.key !== "Enter") return;
    handleLogin();
  };

  const onHandleChage = (event: any) => {
    setLogin({
      ...login,
      [event.target.id]: event.target.value
    });
  };
  return (
    <NewMainContainer>
      <LoginBox>
        <MG_BOT_40>
          <Image src={Images.logoImg} alt="logo" width={254} height={52} />
        </MG_BOT_40>
        <MG_BOT_40>
          <MG_BOT_20>
            <TextInputControl
              type="text"
              id="staff_id"
              autoComplete={"off"}
              title={"Account"}
              styles={InputStyles}
              placeholder="Enter a Account"
              onChange={onHandleChage}
              isValidate={true}
              value={login.staff_id}
              //formik={formik}
            />
          </MG_BOT_20>
          <TextInputControl
            type="auth"
            id="staff_pw"
            title={"Password"}
            autoComplete={"off"}
            styles={InputStyles}
            onChange={onHandleChage}
            placeholder="Enter a Password"
            isValidate={true}
            onKeyPress={handleEnterPress}
            value={login.staff_pw}
            //formik={formik}
          />
        </MG_BOT_40>
        <MG_BOT_40>
          <LoginBtn variant="contained" onClick={handleLogin}>
            LOGIN
          </LoginBtn>
        </MG_BOT_40>
        <BotDiv>
          <div>
            <Typography color={"#9b9b9b"}>
              Copyright(C) Sungwon 2002, All rights reserved.
            </Typography>
          </div>
        </BotDiv>
        <Divide />
      </LoginBox>
      {loading && (
        <LoadingWrap>
          <Image src={Images.loading} width={55} height={55} alt="loading" />
        </LoadingWrap>
      )}
    </NewMainContainer>
  );
}
